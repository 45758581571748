document.addEventListener("DOMContentLoaded", () => {
  const $navbarBurgers = document.querySelectorAll(".navbar-burger");

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {
    // Add a click event on each of them
    $navbarBurgers.forEach((el) => {
      el.addEventListener("click", () => {
        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);
        const $navbar = document.querySelectorAll(".navbar")[0];
        const $backdrop = document.querySelectorAll(".backdrop")[0];

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle("is-active");
        if (
          (el.classList.contains("is-active") &&
            !$navbar.classList.contains("scrolled")) ||
          (window.scrollY === 0 && $navbar.classList.contains("scrolled"))
        ) {
          $navbar.classList.toggle("scrolled");
        }
        $target.classList.toggle("is-active");
        $backdrop.classList.toggle("is-active");
      });
    });
  }

  const closeMenu = () => {
    document.querySelectorAll(".backdrop")[0].classList.toggle("is-active");
    const el = document.querySelectorAll(".navbar-burger")[0];
    el.classList.toggle("is-active");
    document.getElementById(el.dataset.target).classList.toggle("is-active");

    const $navbar = document.querySelectorAll(".navbar")[0];
    if (
      (el.classList.contains("is-active") &&
        !$navbar.classList.contains("scrolled")) ||
      (window.scrollY === 0 && $navbar.classList.contains("scrolled"))
    ) {
      $navbar.classList.toggle("scrolled");
    }
  };

  document
    .querySelectorAll(".backdrop")[0]
    .addEventListener("click", closeMenu);
  document
    .querySelectorAll(".navbar-close")[0]
    .addEventListener("click", closeMenu);

  AOS.init();

  window.addEventListener("scroll", function () {
    document.querySelectorAll(".page").forEach((el) => {
      if (el.getBoundingClientRect().top - 160 <= 0) {
        var id = el.getAttribute("id");
        document.querySelectorAll("a.navbar-item.js-scrollTo").forEach((e) => {
          e.classList.remove("overline");
        });
        document
          .querySelectorAll('a.navbar-item.js-scrollTo[href$="#' + id + '"]')
          .forEach((e) => {
            e.classList.add("overline");
          });
      }
    });
  });
});
